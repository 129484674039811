<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";

// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "แก้ไขปัจจัยในการตัดสินใจซื้อรถ",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      // tableData: tableData,

      title: "แก้ไขปัจจัยในการตัดสินใจซื้อรถ",
      items: [
        {
          text: "ข้อมูลหลัก",
          active: true,
        },
        {
          text: "ข้อมูลหลักผู้แทนจำหน่าย",
          active: true,
        },
        {
          text: "ปัจจัยในการตัดสินใจซื้อรถ",
          href: "/d-factor",
        },
        {
          text: "แก้ไขปัจจัยในการตัดสินใจซื้อรถ",
          active: true,
        },
      ],
      overlayFlag: false,
      selected: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      branchMaster: [],
      totalRecords: 0,
      isHidden: false,

      // selectMode: "single",
      userBranchId: "",
      branchId: "",
      //   payMethCode: "",
      factorCode: "",
      manageBranch: "",
      shortNameEn: "",
      shortNameTh: "",
      nameTh: "",
      factorId: this.$route.params.factorId,
      nameEn: "",
      payMethod: "",
      submitform: false,
      errormessage: "กรุณากรอกข้อมูลให้ครบ",

      userBranch: "",
      filterOn: [],
      rows: [],
      sortBy: "age",
      sortDesc: false,
    };
  },
  validations: {
    nameTh: {
      required,
    },
    nameEn: {},
    manageBranch: {},
    shortNameTh: {},
    shortNameEn: {},
  },

  computed: {
    /**
     * Total no. of records
     */
    // rows() {
    //   return this.rows.length;
    // },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });
    this.branchMaster = user.userAccessBranch;
    this.userBranchId = user.branchId;
    this.userBranch = arrayBranch;
    this.totalRows = this.rows.total;
  },
  created() {
    this.getShow();
  },
  methods: {
    /**
     * Search the table data with search input
     */

    tooltipForm() {
      // console.log(this.$v)
      this.submitform = true;
      this.$v.$touch();
      if (this.$v.$invalid != true) {
        this.submitDfi();
      }
    },
    getShow: function () {
      this.overlayFlag = true;

      useNetw
        .get("api/d-factor/show", {
          params: {
            factorId: atob(this.factorId),
          },
        })
        .then((response) => {
          this.factorCode = response.data.data.factorCode;
          this.nameTh = response.data.data.nameTh;
          this.nameEn = response.data.data.nameEn;
          this.shortNameTh = response.data.data.shortNameTh;
          this.shortNameEn = response.data.data.shortNameEn;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.message + "<br>" + err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    submitDfi: function () {
      this.overlayFlag = true; //skeleton true
      useNetw
        .put("api/d-factor/update", {
          factorId: atob(this.factorId),
          factorCode: this.factorCode,
          nameTh: this.nameTh,
          nameEn: this.nameEn,
          shortNameTh: this.shortNameTh,
          shortNameEn: this.shortNameEn,
          manageBranch: this.manageBranch,
        })
        .then((response) => {
          Swal.fire(
            appConfig.swal.title.postSuccess,
            JSON.stringify(response.data.message),
            appConfig.swal.type.success
          );
          this.$router.push({
            name: "main-d-factor",
          });
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          this.overlayFlag = false; //skeleton true
        });
    },
  },
  middleware: "authentication",
};
</script>
<style>
.modal-custom .modal-dialog {
  max-width: 95%;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-overlay
      :show="overlayFlag"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-md-4 col-xl-2">
                      <div class="mb-3 position-relative">
                        รหัสปัจจัยในการตัดสินใจซื้อรถ <br />
                        <input
                          type="text"
                          class="form-control"
                          placeholder="รหัสปัจจัยในการตัดสินใจซื้อรถ"
                          v-model="factorCode"
                        />
                      </div>
                    </div>
                    <div class="col-md-4 col-xl-3">
                      <div class="mb-3 position-relative">
                        <code> * </code>ชื่อ (ไทย):
                        <b-form-input
                          v-model="nameTh"
                          class="form-control"
                          :class="{
                            'is-invalid': submitform && $v.nameTh.$error,
                          }"
                        ></b-form-input>
                        <div
                          v-if="submitform && $v.nameTh.$error"
                          class="invalid-tooltip"
                        >
                          <span v-if="!$v.nameTh.required">{{
                            errormessage
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 col-xl-3">
                      <div class="mb-3 position-relative">
                        ชื่อ (อังกฤษ):
                        <b-form-input
                          v-model="nameEn"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-4 col-xl-2">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (ไทย):
                        <b-form-input
                          v-model="shortNameTh"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-md-4 col-xl-2">
                      <div class="mb-3 position-relative">
                        ชื่อย่อ (อังกฤษ):
                        <b-form-input
                          v-model="shortNameEn"
                          class="form-control"
                        ></b-form-input>
                      </div>
                    </div>
                    <div class="col-1"></div>

                    <div class="col-12 col-sm-12 col-md-12">
                      <label for=""> </label><br />
                      <b-button
                        class="btn float-end m-1"
                        variant="success"
                        @click="tooltipForm"
                      >
                        บันทึก
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>
